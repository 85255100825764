import React from "react";


function getEnv() {
  const hostname = window.location.hostname;
  if (hostname === "jd-ces-test.s3-website-us-east-1.amazonaws.com") return "staging";
  if (hostname === "ces2023.deere.com") return "prod";
  return "local";
}

function getLaunch() {
  // const now = new Date();
  // const softStart = new Date("2023-01-05T00:30:00-06:00");
  // const hardStart = new Date("2023-01-05T12:00:00-06:00");

  // // hours til softStart
  // const hoursTilSoftStart = ((softStart.getTime() - now.getTime()) / 1000 / 60 / 60);
  // const hoursTilHardStart = ((hardStart.getTime() - now.getTime()) / 1000 / 60 / 60);

  // console.log({ hoursTilSoftStart, hoursTilHardStart})
  // if (now < softStart) return "tease";
  // if (now < hardStart) return "soft";
  return "hard";
}

let launch = getLaunch();
let env = getEnv();

const teaserState = {
  launch,
  env
};

export const TeaserStateContext = React.createContext<{
  launch: string;
  env: string;
  setLaunch: (launch: string) => void;
}>({
  launch: "tease",
  env: getEnv(),
  setLaunch: () => {}
});

export const TeaserProvider: React.FunctionComponent<{ children: React.ReactNode }> = ({ children }) => {
  const [currentLaunch, setLaunch] = React.useState(teaserState.launch);

  return (
    <TeaserStateContext.Provider value={{...teaserState, launch: currentLaunch, setLaunch }}>
      {children}
    </TeaserStateContext.Provider>
  );
};
