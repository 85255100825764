import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { TeaserProvider } from "./util/app-state";
import { HTTPSRedirect } from "./util/https-redirect";
import { ScrollToTop } from "./util/ScrollToTop";

export interface IScrollToTopProps {
  children: React.ReactNode;
}

ReactDOM.render(
  <React.StrictMode>
    <TeaserProvider>
      <BrowserRouter>
        <HTTPSRedirect />
        <ScrollToTop>
          <App />
        </ScrollToTop>
      </BrowserRouter>
    </TeaserProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// eslint-disable-next-line no-restricted-globals
history.scrollRestoration = "manual";
