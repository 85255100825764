import * as React from "react";
import "./MeetTheExperts.scss";

import Footer from "../../components/Footer";
import { ModalContent, ModalContext } from "../../components/modal/Modal";
import { EXPERTS_2022 } from "./EXPERTS_2022";

export interface IExpertSlideProps {
  src: string;
  name: string;
  bio: string | JSX.Element;
  title: string;
  company?: string;
  group?: string;
}

const ExpertSlide: React.FunctionComponent<IExpertSlideProps> = (props) => {
  const imgSrc = `/img/experts/${props.src}-2x.jpg`;
  const { handleModal } = React.useContext(ModalContext);

  const title = [props.title, props.company, props.group]
    .filter((x) => Boolean(x))
    .map((x) => {
      // if x includes John Deere, split it and wrap John Deere in .nobr span
      if (x?.includes("John Deere")) {
        const split = x.split("John Deere");
        return (

          <span>
            {split[0]}
            <span className="nobr">John Deere</span>
            {split[1]}
          </span>
        );
      }

      return (
        <span className={""}>{x}</span>
      );
    });
  // .join(", ");

  const modalContent: ModalContent = {
    title: props.name,
    img: imgSrc,
    definition: (
      <div className="ExpertSlide__modal">
        <div className="ExpertSlide__title">{title}</div>
        <div className="ExpertSlide__bio">{props.bio}</div>
      </div>
    ),
  };

  return (
    <div className="ExpertSlide" onClick={() => handleModal(modalContent)}>
      <img src={imgSrc} alt={props.name} />
      <div className="ExpertSlide__content">
        <h3 className="ExpertSlide__name">{props.name}</h3>
        <div className="ExpertSlide__title">{title}</div>
        <div className="ExpertSlide__link">Learn more</div>
      </div>
    </div>
  );
};

export const MeetTheExperts: React.FunctionComponent = (props) => {
  return (
    <div className="MeetTheExperts">
      <div className="page-content">
        <h2>Meet the Experts</h2>
        <div className="expert-slides">
          {EXPERTS_2022.map((expert) => (
            <ExpertSlide
              key={expert.name}
              src={expert.src}
              name={expert.name}
              bio={expert.bio}
              title={expert.title}
              group={expert.group}
              company={expert.company}
            />
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};
