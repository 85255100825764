import classNames from "classnames";
import * as React from "react";
import { Link } from "react-router-dom";
import "./OutlineButton.scss";

interface IOutlineButtonProps {
    text: string;
    onClick: () => void;
    className?: string;
    active?: boolean;
}


export const OutlineButton: React.FunctionComponent<IOutlineButtonProps> = (props) => {
  return <div className={classNames("OutlineButton", props.className, { green: props.active })} onClick={() => props.onClick()}>
      {props.text}
  </div>;
};

interface ILinkOutlineButtonProps {
  text: string;
  to: string;
  className?: string;
}

export const LinkOutlineButton: React.FunctionComponent<ILinkOutlineButtonProps> = (props) => {
  return <Link className={classNames("OutlineButton", props.className)} to={props.to}>
      {props.text}
  </Link>;
};

interface IAnchorOutlineButtonProps {
  text: string;
  to: string;
  className?: string;
  active?: boolean;
}

export const AnchorOutlineButton: React.FunctionComponent<IAnchorOutlineButtonProps> = (props) => {
  return <a className={classNames("OutlineButton", props.className, { green: props.active })} href={props.to} target="_blank" rel="noreferrer">
      {props.text}
  </a>;
};