/* eslint-disable jsx-a11y/iframe-has-title */
import * as React from "react";
import "./VideoOverlay.scss";
import { ReactComponent as Close } from "../img/close.svg";
import classNames from "classnames";

interface IVideoOverlayProps {
  src: string;
  onOverlayClicked: () => void;
  show: boolean;
}

export const VideoOverlay: React.FunctionComponent<IVideoOverlayProps> = (props) => {
  return (
    <div className={classNames("VideoOverlay", { show: props.show })} onClick={() => props.onOverlayClicked()}>
        <div className="iframe-container">
        <Close />
        <iframe
            width="560"
            height="315"
            src={props.src}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
        />
        </div>
    </div>
  );
};
