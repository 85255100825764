import React from "react";
import { useLocation } from "react-router-dom";
import { IScrollToTopProps } from "../index";

export const ScrollToTop: React.FunctionComponent<IScrollToTopProps> = (props) => {
  const { pathname } = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return <div>{props.children}</div>;
};
