import { useContext, useEffect } from "react";
import { TeaserStateContext } from "./app-state";

export const HTTPSRedirect = () => {
  const { env } = useContext(TeaserStateContext);

  useEffect(() => {
    if (env === "prod" && window.location.protocol !== "https:") {
      // redirect to https
        window.location.href = window.location.href.replace("http:", "https:");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
